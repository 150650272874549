<template>
  <v-container>
    <v-carousel cycle hide-delimiter-background height="auto">
      <v-carousel-item v-for="image in images" :key="image">
        <v-img :src="image"></v-img>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: 'HomeBanner',
  data() {
    return {
      chain: {
        id: '',
      },
      images: [],
    };
  },

  mounted() {
    this.fetchImages();
  },

  methods: {
    async fetchImages() {
      axios({
        method: "get",
        url: process.env.VUE_APP_API_URL + '/api/cadena/prefijo/' + this.$route.params.chain,
      })
        .then((response) => {
          this.images = response.data.data.plantilla_pagina.carusel
        })
        .catch(err => {
          console.log('Error de conexión: ' + err.message);
        });
    },
  },
};
</script>