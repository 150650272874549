<template v-slot:extension>
  <v-container class="pt-0">
    <app-bar v-if="!$vuetify.breakpoint.smAndDown"></app-bar>

    <v-row :class="!$vuetify.breakpoint.smAndDown ? 'pt-8' : 'pt-0'" justify="center">
      <v-col lg="6" md="6" class="pl-0 pr-0" v-if="!$vuetify.breakpoint.smAndDown">
        <home-banner></home-banner>
      </v-col>
      <v-col lg="6" md="6" sm="12" xs="12" class="pl-0 pr-0">
        <wizard-register></wizard-register>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import HomeBanner from '@/components/carrousel/BannerHome.vue';
  import WizardRegister from "@/components/wizard/Wizard.vue";
  import AppBar from "@/components/appbar/AppBar.vue";
</script>

<script>
  export default {
    data () {
      return {

      }
    },
  }
</script>
<style>
  .white--text {
    color: white !important;
  }
</style>