<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import axios from "axios";

export default {
  name: 'App',

  data: () => ({
    users: {
      clientID: process.env.VUE_APP_API_USER,
      clientSecret: process.env.VUE_APP_API_PASS
    },
  }),
  methods: {
    tokenLog() {
      axios.post(process.env.VUE_APP_API_URL + '/api/Auth/token', this.users)
        .then(res => {
          localStorage.setItem('token', res.data.token);
        })
        .catch(err => {
          console.log('Error de conexión: ' + err.message);
          this.tokenLog();
        });
    },
  },
  created() {
    this.tokenLog();
  },
};
</script>

<script setup>

</script>