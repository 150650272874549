<template>
  <v-container>
    <v-app-bar app flat height="130px" color="white" style="padding:2px 5px 15px 5px;">
      <div style="width: auto; vertical-align: center;">
        <v-img :src="this.chain.logo" contain alt="KFC" max-width="180px" max-height="100px"></v-img>
      </div>
      <v-spacer></v-spacer>
      <v-text-field
          filled
          dense
          rounded
          append-icon="mdi-magnify"
          single-line
          hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-email</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-app-bar>
  </v-container>
</template>

<script>
  import axios from "axios";

  export default {
    name: 'AppBar',
    data () {
      return {
        chain: {
          logo: '',
        },
      }
    },

    methods: {
      getChain() {
        axios({
          method: "get",
          url: process.env.VUE_APP_API_URL + '/api/cadena/prefijo/' + this.$route.params.chain,
        })
          .then((response) => {
            // Carga de variables básicas
            this.chain.logo = response.data.data.cadena_logo;
          })
          .catch(err => {
            console.log('Error de conexión: ' + err.message);
          });
      },
    },

    created() {
      this.getChain();
    },
  }
</script>