<template>
  <qrcode-vue
      :value="value"
      :options="options"
  ></qrcode-vue>
</template>

<script>
import QrcodeVue from '@chenfengyuan/vue-qrcode';
export default {
  name: 'QrCode',
  components: {
    QrcodeVue,
  },
  props: {
    value: {
      type: String,
      default: 'Welcome'
    }
  },
  data() {
    return {
      options: {
        errorCorrectionLevel: 'H',
        width: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
      },
    };
  },
};
</script>